<template>
<section class="brandinfo-wrapper">
    <h1 class="header-title">支付设置</h1>

    <!-- form -->
    <el-form class="form-content" ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
        <!-- <el-form-item label="appid" prop="appid">
            <el-input class="pay-input" v-model="form.appid" placeholder="请输入appid"></el-input>
        </el-form-item> -->
        <el-form-item label="小程序名称" prop="name">
            <el-input class="pay-input" v-model="form.name" placeholder="请输入小程序名称"></el-input>
        </el-form-item>
        <el-form-item label="API密钥" prop="api">
            <el-input class="pay-input" v-model="form.api" placeholder="请输入API密钥"></el-input>
        </el-form-item>
        <el-form-item>
            
        </el-form-item>
    </el-form>
    <div class="footer-btn">
        <el-button @click="resetHandler">取消</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
    </div>
</section>
</template>

<script>
let init = false;
export default {
    data(){
        return {
            form:{
                appid:'',
                name:'',
                api:''
            },
            rules:{
                appid:[{required: true, message: '请输入appid', trigger: 'blur'}],
                name:[{required: true, message: '请输入小程序名称', trigger: 'blur'}],
                api:[{required: true, message: '请输入API密钥', trigger: 'blur'}],
            }
        }
    },
    methods:{
        async saveHandler(){ 
            if(init){
                this.$message.error('请不要重复提交');
                return;
            }
            this.$refs.form.validate(async valid=>{
                if(!valid) return;
                await this.$https.wxApplication({
                    appSecret: this.form.api,
                    appName: this.form.name
                });
                init = true;
                setTimeout(()=>{init=false;},5*1000)
                this.$message.success('设置修改成功');
            })
        },
        resetHandler(){
            this.$refs.form.resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
.brandinfo-wrapper {
    padding: 10px;
    height: 100%;
    position: relative;
    background: #fff;

    .header-title {
        font-size: 16px;
        line-height: 22px;
        padding: 13px 0 12px 0;
        border-bottom: 1px solid #D9D9D9;
    }

    .form-content {
        max-width: 800px;
        margin-top: 40px;

        .pay-input {
            width: 250px;
        }
    }
}
</style>